var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('v-card',{staticClass:"px-5",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Buscar","placeholder":"Buscar","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right my-auto",attrs:{"cols":"4","offset":"4"}},[_c('v-btn',{attrs:{"small":"","color":"success","loading":_vm.loadingNuevo},on:{"click":function($event){return _vm.nuevo()}}},[_vm._v("NUEVO")]),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","loading":_vm.loadingFiltrar,"color":"#2962FF","dark":""},on:{"click":function($event){return _vm.filtrar()}}},[_vm._v("FILTRAR")]),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","color":"#004D40","dark":""}},[_vm._v("EXPORTAR")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.$store.state.begend.lstBegend,"item-key":"port_beginend_id","loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!!item.status == 1 ? "Activo" : "Inactivo")+" ")]}},{key:"item.colors",fn:function(ref){
var item = ref.item;
return [(item.color)?_c('v-chip',{attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.color)+" ")]):_vm._e()]}},{key:"item.statuscalls",fn:function(ref){
var item = ref.item;
return [(item.status_calls == 1)?_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v("Si")]):_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v("No")])]}},{key:"item.statuscalls_all",fn:function(ref){
var item = ref.item;
return [(item.status_calls_all == 1)?_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v("Si")]):_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v("No")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.ver(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("Ver")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning","dark":""},on:{"click":function($event){return _vm.editar(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),(item.status == 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error","dark":""},on:{"click":function($event){return _vm.eliminar(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]):_vm._e()]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }